import * as Sentry from '@sentry/remix';
import type { Invite, InviteInstruction, InviteKey } from '~/services/organizations.server';
import type { EnterpriseTransferBegin, EnterpriseUpdateRoleBegin } from '~/services/enterprise.server';
import type { MemberProjectKey, Whoami } from '~/generated-types';
import { InvalidPassphraseError, NeedPassphraseError, sanitizePassphrase } from '~/lib/session.client';
import { loadSrp } from './srp.client';

export async function buildInviteByInstruction(instruction: InviteInstruction, rawProjectKeys: DecryptedProjectKey[]) {
  let inviteKeys: InviteKey[] = [];

  if (rawProjectKeys?.length) {
    const { encryptRSAWithJWK } = await loadSrp();

    const inviteePublicKey = JSON.parse(instruction.inviteePublicKey);

    inviteKeys = rawProjectKeys.map((key) => {
      const reEncryptedSymmetricKey = encryptRSAWithJWK(inviteePublicKey, key.symmetricKey);
      return {
        projectId: key.projectId,
        encSymmetricKey: reEncryptedSymmetricKey,
        autoLinked: instruction.inviteeAutoLinked,
      };
    });
  }

  return {
    inviteeId: instruction.inviteeId,
    inviteeEmail: instruction.inviteeEmail,
    inviteKeys,
  } as Invite;
}

export async function buildMemberProjectKey(
  accountId: string,
  projectId: string,
  publicKey: string,
  rawProjectKey: string,
) {
  const acctPublicKey = JSON.parse(publicKey);
  const { encryptRSAWithJWK } = await loadSrp();
  const encSymmetricKey = encryptRSAWithJWK(acctPublicKey, rawProjectKey);

  return {
    projectId,
    accountId,
    encSymmetricKey,
  } as MemberProjectKey;
}

export async function buildTransfer(transfer: EnterpriseTransferBegin, inviterPrivateKey: JsonWebKey) {
  let inviteKeys: InviteKey[] = [];
  if (transfer.inviteKeys?.length) {
    inviteKeys = await Promise.all(
      transfer.inviteKeys.map((key) => reEncryptInviteKey(key, inviterPrivateKey, transfer.inviteePublicKey)),
    );
  }

  return {
    inviteeId: transfer.inviteeId,
    inviteeEmail: transfer.inviteeEmail,
    inviteKeys,
  } as Invite;
}

export async function buildUpdateRoleData(updateBeginData: EnterpriseUpdateRoleBegin, inviterPrivateKey: JsonWebKey) {
  let inviteKeys: InviteKey[] = [];
  if (updateBeginData.inviteKeys?.length) {
    inviteKeys = await Promise.all(
      updateBeginData.inviteKeys.map((key) =>
        reEncryptInviteKey(key, inviterPrivateKey, updateBeginData.inviteePublicKey),
      ),
    );
  }

  return {
    inviteeId: updateBeginData.inviteeId,
    inviteeEmail: updateBeginData.inviteeEmail,
    inviteKeys,
  } as Invite;
}

export type PrivateKeyParams = Pick<Whoami, 'email' | 'saltEnc' | 'encPrivateKey' | 'encSymmetricKey' | 'encDriverKey'>;

export async function getPrivateKey(params: PrivateKeyParams, rawPassphrase: string | null) {
  let privateKey: string | null = null;

  if (rawPassphrase !== null) {
    const { decryptAES } = await loadSrp();
    const secret = await deriveSymmetricKey(params, rawPassphrase);
    const { encPrivateKey, encSymmetricKey } = params;

    let symmetricKey: string;
    try {
      symmetricKey = decryptAES(secret, JSON.parse(encSymmetricKey));
    } catch (err) {
      Sentry.captureException(err);
      console.log('Failed to decrypt wrapped private key', err);
      throw new InvalidPassphraseError();
    }

    privateKey = decryptAES(JSON.parse(symmetricKey), JSON.parse(encPrivateKey));
  } else {
    throw new NeedPassphraseError();
  }

  return JSON.parse(privateKey) as JsonWebKey;
}

export async function reEncryptProjectKey(publicKey: JsonWebKey, symmetricKey: string) {
  const { encryptRSAWithJWK } = await loadSrp();
  return encryptRSAWithJWK(publicKey, symmetricKey);
}

async function reEncryptInviteKey(key: InviteKey, inviterPrivateKey: JsonWebKey, serializedInviteePublicKey: string) {
  try {
    const inviteePublicKey = JSON.parse(serializedInviteePublicKey);
    const { decryptRSAWithJWK, encryptRSAWithJWK } = await loadSrp();
    const symmetricKey = decryptRSAWithJWK(inviterPrivateKey, key.encSymmetricKey);
    const encProjectSymmetricKey = encryptRSAWithJWK(inviteePublicKey, symmetricKey);
    return {
      encSymmetricKey: encProjectSymmetricKey,
      projectId: key.projectId,
      autoLinked: key.autoLinked,
    } as InviteKey;
  } catch (error) {
    throw error;
  }
}

export type EncryptedProjectKey = {
  projectId: string;
  encKey: string;
};
export type DecryptedProjectKey = {
  projectId: string;
  symmetricKey: string;
};
export async function decryptProjectKeys(decryptionKey: JsonWebKey, projectKeys: EncryptedProjectKey[]) {
  try {
    const { decryptRSAWithJWK } = await loadSrp();

    const promises = projectKeys.map((key) => {
      const symmetricKey = decryptRSAWithJWK(decryptionKey, key.encKey);
      return {
        projectId: key.projectId,
        symmetricKey,
      };
    });

    const decrpyted = await Promise.all(promises);

    return decrpyted as DecryptedProjectKey[];
  } catch (error) {
    throw error;
  }
}

export async function decryptEncryptedManagedKey(privateKey: JsonWebKey, encryptedKey: string) {
  const { decryptRSAWithJWK } = await loadSrp();
  const managedPassphrase = decryptRSAWithJWK(privateKey, encryptedKey);

  return managedPassphrase;
}

async function deriveSymmetricKey(params: PrivateKeyParams, rawPassphrase: string): Promise<string> {
  const { deriveKey } = await loadSrp();

  const passPhrase = sanitizePassphrase(rawPassphrase);
  const { encDriverKey, saltEnc } = params;

  return await deriveKey(passPhrase, encDriverKey, saltEnc);
}
